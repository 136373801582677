<script lang="ts">
	export let headingIcon: ImageMetadata | undefined;
	export let heading: string | undefined;
	export let description: string;
	export let author: string;
	export let authorPosition: string | undefined;
	export let authorCompany: string | undefined;
</script>

<figure
	class={`rounded-[40px] md:rounded-[35px] shadow-md dark:bg-gray-800 dark:border-gray-700 ${heading ? 'bg-complementary-grey' : 'bg-[#FFF]'} w-[80vw] ${heading ? 'md:w-[473px]' : 'md:w-[350px]'} h-full`}
>
	<figcaption
		class={`${heading ? 'pt-14 pb-10 md:pb-16 md:pt-24' : 'pb-10 md:pb-16'} h-full justify-between flex flex-col`}
	>
		{#if heading}
			<div class="mt-8 px-10">
				<h3 class="font-abhaya text-4xl md:text-5xl font-medium leading-[2.15rem] text-center text-[#B85A27]">
					<span class="relative"
						><span>{heading}</span>
						<div class="absolute -left-6 md:-left-7 -top-4 md:-top-3">
							<svg
								class="h-6 aspect-[63.0__44.0]"
								role="presentation"
								viewBox="0 0 63 44"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									width="44.1553"
									height="19.3669"
									rx="9.68344"
									transform="matrix(-0.478333 0.878178 -0.85804 -0.513583 36.1016 7.4043)"
									stroke="#E2AA2F"
									stroke-width="2"
								></rect>
								<rect
									width="44.1553"
									height="19.3669"
									rx="9.68344"
									transform="matrix(-0.478333 0.878178 -0.85804 -0.513583 65.1016 7.4043)"
									stroke="#E2AA2F"
									stroke-width="2"
								></rect>
							</svg>
						</div></span
					>
				</h3>
			</div>
		{:else}
			<img
				class="w-32 md:w-52 mx-auto"
				width="206"
				src={headingIcon?.src}
				alt=""
				loading="lazy"
				style="aspect-ratio: 304/250;"
			/>
		{/if}

		<p class={`text-primary font-normal text-center md:text-xl px-8 ${heading ? 'pt-11' : 'pt-0'} mb-11 mx-auto`}>
			{description}
		</p>

		<div class="text-primary-800 w-full flex flex-col justify-center items-center text-center md:text-xl">
			<p class={`${!heading ? 'text-[#B85A27]' : ''} font-bold`}>{author}</p>
			{#if authorPosition}
				<p class="font-normal">{authorPosition}</p>
			{/if}
			{#if authorCompany}
				<p class="font-normal">{authorCompany}</p>
			{/if}
		</div>
	</figcaption>
</figure>
