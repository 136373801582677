<script lang="ts">
	import { Splide, SplideSlide } from '@splidejs/svelte-splide';
	import ReviewCard from '~/components/atoms/ReviewCard.svelte';
	import '@splidejs/svelte-splide/css';
	import { isMobile } from '~/utils/store';
	// import '@splidejs/~/components/atoms/ReviewCard.svelte@splidejs/svelte-splide/css';

	export let heading: string | undefined = undefined;
	export let stories: StoryType[];
	export let containerClass: SliderContainersType;
	export let className: string;



</script>

<section
	id="review-slider"
	data-id={containerClass}
	class={`px-4 md:px-36 ${className}`}
>
	{#if heading}
		<h2 class="heading mb-8 text-start md:mb-12">{heading}</h2>
	{/if}
	<div class="-mx-4">
		<div class="overflow-x-auto snap-mandatory snap-x grid gap-x-8 stories hide-scroll-bar px-[50%] md:px-0" style={`--cardsLength: ${stories.length}`}>
			{#each stories as story}
				<div class="snap-center snap-always rounded-2xl grid place-items-center my-2 transition-transform duration-200 aria-hidden:scale-[0.85]  md:aria-hidden:scale-100">
					<ReviewCard
						headingIcon={story.headingIcon}
						heading={story.heading}
						description={story.description}
						author={story.author}
						authorPosition={story.authorPosition}
						authorCompany={story.authorCompany}
					/>
				</div>
			{/each}
		</div>
		<!-- {#if $isMobile !== undefined}
			<Splide
				options={{
					arrows: false,
					gap: $isMobile ? undefined : '4em',
					padding: $isMobile ? { left: '10%', right: '10%' } : {},
					pagination: false,
					perPage: $isMobile ? 1 : 3,
					type: 'slide',
					width: '100%',
				}}
			>
				{#each stories as story}
					<SplideSlide aria-roledescription="slide" class="rounded-2xl grid place-items-center my-2 transition-transform duration-200 aria-hidden:scale-[0.85] -mr-3 last:mr-0 last:md:mr-auto md:aria-hidden:scale-100">
						<ReviewCard
							heading={story.heading}
							description={story.description}
							author={story.author}
							authorPosition={story.authorPosition}
							authorCompany={story.authorCompany}
						/>
					</SplideSlide>
				{/each}
			</Splide>
		{/if} -->
	</div>
</section>

<style>
	.stories {
		grid-template-columns: repeat(var(--cardsLength), 1fr);
	}
</style>
